export default function DisclaimerText() {
  return (
    <div className="px-6 lg:px-0 lg:pr-4 lg:pt-4">
      <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-primary-dark -z-10" />

      <div className="mt-28 mx-auto max-w-4xl text-center">
        <p className="text-pretty text-md/8 font-medium text-gray-400">
          Disclaimer: The USA Unity Coin is provided as public evidence of a
          contribution. It is not intended to be an investment or to have or
          maintain value. The USA Unity Coin provides no utility beyond
          signifying that a contribution has been made. No entity or individual
          is obligated or intends to create value for the USA Unity Coin. You
          will receive USA Unity Coin solely as a result of your contribution;
          there is no purchase or sale of USA Unity Coin.
        </p>
      </div>
    </div>
  );
}
