import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AppKitProvider } from "./AppKitSetup"; // Import the AppKit setup
import { WalletProvider } from "./contexts/WalletContext";

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <AppKitProvider>
            <WalletProvider>
                <App />
            </WalletProvider>
        </AppKitProvider>
    </React.StrictMode>,
);
