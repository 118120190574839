export default function Leadership() {
  return (
    <div className="py-24 overflow-hidden relative">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 relative">
        <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-4 lg:items-start">
          {/* Content */}
          <div className="lg:col-span-3 lg:pr-4 lg:pt-4">
            <div className="mx-auto max-w-3xl lg:mx-0">
              <h2 className="text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl">
                Leading the Way in Innovation For Our Economic Future
              </h2>
              <p className="mt-6 text-xl/8 text-gray-300">
                USA Unity Coin was founded with a clear vision, to bridge the
                gap between blockchain technology and civic engagement, in order
                to help Americans make a real impact.
              </p>
              <p className="mt-6 text-xl/8 text-gray-300">
                At the helm of this groundbreaking initiative is{" "}
                <strong>Alex Lightman</strong>, a visionary in the blockchain
                space and a respected policy advisor. His extensive background
                in technology and government relations has made him a pivotal
                figure in advancing the integration of blockchain technologies
                into the political and economic arenas.
              </p>
              <p className="mt-6 text-xl/8 text-gray-300">
                Guided by Alex Lightman's commitment to transparency and
                integrity, USA Unity Coin surpasses user expectations. Alex
                contributed $1 million to the USA Unity Coin (UUC), showcasing
                his commitment to support meaningful initiatives aligned with
                the project’s mission.
              </p>
            </div>
          </div>

          {/* Image */}
          <div className="lg:col-span-1">
            <div className="mt-8 sm:mt-12 lg:mt-24 mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
              <img
                alt="Alex Lightman"
                src="/Alex.jpg"
                className="w-full max-w-[50%] lg:max-w-none mx-auto rounded-3xl object-cover shadow-xl"
              />
            </div>
          </div>
        </div>

        {/* CTA Button */}
        <div className="mt-16 text-center">
          <a
            href="/about"
            className="inline-block rounded-md bg-white/10 px-8 py-2.5 text-lg font-semibold text-white shadow-[0_0_15px_rgba(255,255,255,0.1)] transition-all hover:bg-blue-400/30 hover:shadow-[0_0_20px_rgba(96,165,250,0.4)] relative group border border-white/10"
          >
            <span className="absolute -inset-0.5 rounded-lg bg-gradient-to-r from-blue-200/20 via-blue-200/30 to-blue-200/20 opacity-0 transition-opacity group-hover:opacity-40 blur" />
            <span className="relative">Learn More About Us</span>
          </a>
        </div>

        {/* Testimonials */}
        <div className="mt-24 lg:px-4 grid grid-cols-1 gap-8 sm:grid-cols-2">
          {/* First Testimonial */}
          <div className="group relative flex flex-col rounded-2xl backdrop-blur-xl p-8 transition-all duration-300">
            {/* Base glass effect */}
            <div className="absolute inset-0 rounded-2xl bg-gradient-to-br from-white/[0.075] via-white/[0.05] to-transparent" />

            {/* Hover with blue tint */}
            <div className="absolute inset-0 rounded-2xl bg-gradient-to-br from-blue-500/[0.15] via-blue-400/[0.1] to-blue-300/[0.05] opacity-0 group-hover:opacity-100 transition-all duration-300" />

            {/* Glow effect on hover */}
            <div className="absolute inset-0 rounded-2xl shadow-[0_0_15px_rgba(59,130,246,0)] group-hover:shadow-[0_0_15px_rgba(59,130,246,0.15)] transition-all duration-300" />

            {/* Border */}
            <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-700/50 group-hover:ring-blue-500/30 transition-all duration-300" />

            <blockquote className="relative text-xl/8 text-gray-300">
              "Combine a strategic visionary with a creative genius and you've
              got Alex Lightman. He knows how to anticipate trends and
              capitalize on them."
            </blockquote>
            <div className="relative mt-6">
              <p className="text-xl/7 font-semibold text-amber-500 [text-shadow:0_0_7px_rgba(245,158,11,0.3),0_0_10px_rgba(245,158,11,0.2),0_0_21px_rgba(245,158,11,0.1)] transition-all duration-300 hover:[text-shadow:0_0_10px_rgba(245,158,11,0.4),0_0_14px_rgba(245,158,11,0.3),0_0_28px_rgba(245,158,11,0.2)]">
                - Leah T
              </p>
              <p className="text-lg text-gray-400">Blockchain Advisor</p>
            </div>
          </div>

          {/* Second Testimonial */}
          <div className="group relative flex flex-col rounded-2xl backdrop-blur-xl p-8 transition-all duration-300">
            {/* Base glass effect */}
            <div className="absolute inset-0 rounded-2xl bg-gradient-to-br from-white/[0.075] via-white/[0.05] to-transparent" />

            {/* Hover with blue tint */}
            <div className="absolute inset-0 rounded-2xl bg-gradient-to-br from-blue-500/[0.15] via-blue-400/[0.1] to-blue-300/[0.05] opacity-0 group-hover:opacity-100 transition-all duration-300" />

            {/* Glow effect on hover */}
            <div className="absolute inset-0 rounded-2xl shadow-[0_0_15px_rgba(59,130,246,0)] group-hover:shadow-[0_0_15px_rgba(59,130,246,0.15)] transition-all duration-300" />

            {/* Border */}
            <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-700/50 group-hover:ring-blue-500/30 transition-all duration-300" />

            <blockquote className="relative text-xl/8 text-gray-300">
              "Alex is one of the brightest individuals I have ever met! His
              vision, creativity and leadership should be an example of which
              every Internet or IT leader should take note."
            </blockquote>
            <div className="relative mt-6">
              <p className="text-xl/7 font-semibold text-amber-500 [text-shadow:0_0_7px_rgba(245,158,11,0.3),0_0_10px_rgba(245,158,11,0.2),0_0_21px_rgba(245,158,11,0.1)] transition-all duration-300 hover:[text-shadow:0_0_10px_rgba(245,158,11,0.4),0_0_14px_rgba(245,158,11,0.3),0_0_28px_rgba(245,158,11,0.2)]">
                - Robert W
              </p>
              <p className="text-lg text-gray-400">Cyber Security</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
