import React from "react";

export default function TelegramInvite() {
  return (
    <div className="text-center py-8 bg-primary-dark">
      <div className="mx-auto max-w-2xl px-6 lg:px-8">
        <div className="flex flex-col items-center space-y-6">
          <p className="text-pretty text-xl/8 font-medium text-white sm:text-3xl/8 text-center">
            Join Our Secure Telegram Community to Connect and Collaborate in
            Real-Time
          </p>

          <a
            href="https://t.me/THEUSAUNITYCOIN"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center gap-x-2 rounded-md
            bg-amber-500 px-8 py-2.5 text-lg font-semibold text-gray-900
            shadow-[0_0_15px_rgba(251,191,36,0.5)] transition-all
            hover:bg-amber-400 hover:shadow-[0_0_25px_rgba(251,191,36,0.65)]
            focus:outline-none focus:ring-2 focus:ring-amber-400
            focus:ring-offset-2 focus:ring-offset-gray-900 group"
          >
            <img
              src="/telegram.svg"
              alt="Telegram icon"
              className="-ml-0.5 size-5"
            />
            Join Now
          </a>
        </div>
      </div>
    </div>
  );
}
