import React, { useEffect, useState } from "react";
import AboutHero from "../components/About/AboutHero";
import { useNavigate } from "react-router-dom";
import Mission from "../components/About/Mission";
import CoreValues from "../components/About/CoreValues";
import OurStory from "../components/About/OurStory";
import Team from "../components/About/Team";
import JoinUsCta from "../components/About/JoinUsCta";
import { useWallet } from "../contexts/WalletContext";
import { TailSpin } from "react-loader-spinner";
import "./AccountPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import { useBalance } from "wagmi";
import axios from "axios";

const AccountPage = ({ userId }) => {
    const navigate = useNavigate();
    const tokenAddress = import.meta.env.VITE_DEV_TOKEN_CONTRACT_ADDRESS;
    const { address, isConnected, setConnected, disconnect } = useWallet();
    const { data } = useBalance({
        address,
        token: tokenAddress,
    });
    const [eligibleResponse, setEligibleResponse] = useState(null);
    const [eligibleError, setEligibleError] = useState(null);
    const [claimStatus, setClaimStatus] = useState("");
    const [isClaimed, setIsClaimed] = useState(false);
    const [email, setEmail] = useState("");
    const [history, setHistory] = useState("");
    const [wallets, setWallets] = useState([]);
    const [inputEmailValue, setInputEmailValue] = useState("");

    const fetchEligibility = async () => {
        if (email) {
            try {
                const response = await axios.get(
                    `${import.meta.env.VITE_API_BASE_LOCAL_DEV}/users/eligible/${email}`,
                );
                console.log(response.data);
                setEligibleResponse(response.data);
                setEligibleError(null);
            } catch (error) {
                setEligibleError(
                    error.response?.data?.error ||
                        "Failed to fetch eligibility status",
                );
                setEligibleResponse(null);
            }
        }
    };

    // const fetchTokenBalance = async () => {
    //     if (userId) {
    //         const tokenInfo = await Promise.all([
    //             axios.get(
    //                 `${import.meta.env.VITE_API_BASE_LOCAL_DEV}/rpc/tokenAddress`,
    //             ),
    //             axios.get(
    //                 `${import.meta.env.VITE_API_BASE_LOCAL_DEV}/rpc/tokenAbi`,
    //             ),
    //         ]);

    //         const [contractAddress, abi] = tokenInfo;
    //     }

    //     const ownerAddress = address;
    //     var balance = await AppKit.Evm.ReadContractAsync(
    //         contractAddress,
    //         abi,
    //         "balanceOf",
    //         { ownerAddress },
    //     );
    //     var decimals = await AppKit.Evm.ReadContractAsync(
    //         contractAddress,
    //         abi,
    //         "decimals",
    //     );
    //     var finalBalance = tokenBalance / BigInteger.Pow(10, tokenDecimal);
    // };

    const fetchBalance = async () => {
        if (userId) {
            try {
                const response = await axios.get(
                    `${
                        import.meta.env.VITE_API_BASE_LOCAL_DEV
                    }/rpcApi/getBalance/${userId}`,
                );
                if (response.data.transaction?.status === "completed") {
                    setIsClaimed(true);
                } else {
                    fetchEligibility();
                }
            } catch (error) {
                console.error("Error fetching redemption status:", error);
                fetchEligibility();
            }
        }
    };

    // Function to fetch redemption status
    const fetchRedemptionStatus = async () => {
        if (userId) {
            try {
                const response = await axios.get(
                    `${
                        import.meta.env.VITE_API_BASE_LOCAL_DEV
                    }/redemptions/track/${userId}`,
                );
                if (response.data.transaction?.status === "completed") {
                    setIsClaimed(true);
                    setClaimStatus("completed");
                    setHistory(response.data.transaction.blockchain_tx_hash);
                } else if (response.data.transaction?.status === "failed") {
                    setClaimStatus("failed");
                    fetchEligibility();
                } else {
                    fetchEligibility();
                }
            } catch (error) {
                console.error("Error fetching redemption status:", error);
                fetchEligibility();
            }
        }
    };

    useEffect(() => {
        if (isConnected && address && !disconnect) {
        } else {
        }
    }, [isConnected, disconnect]);

    useEffect(() => {
        if (isConnected && userId) {
            console.log("fetching redep");
            fetchRedemptionStatus();
            //fetchEligibility();
            console.log(`claim status :${claimStatus}`);
            console.log(`claim status :${eligibleResponse}`);
            //   fetchBalance();
            //fetchTokenBalance();
        }
    }, [isConnected, userId]);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await axios.get(
                    `${
                        import.meta.env.VITE_API_BASE_LOCAL_DEV
                    }/users/userDetails/${userId}`,
                );
                const user = response.data.user;

                // Set the email and wallets state
                setEmail(user.email || "-");
                setWallets(user.walletAddress || []);
            } catch (error) {
                console.error(
                    "Error fetching user details:",
                    error.response?.data || error.message,
                );
            }
        };

        if (userId) {
            fetchUserDetails();
        }
    }, [userId]);

    const handleClaimClick = async () => {
        if (!email) {
            console.log("no email");
            setClaimStatus("email is missing.");
            return;
        }
        setClaimStatus("Processing");
        try {
            const response = await axios.post(
                `${import.meta.env.VITE_API_BASE_LOCAL_DEV}/redemptions/submit`,
                { email: email },
            );
            setClaimStatus(
                `Successfully claimed, transaction hash: ${response.data.transactionHash}`,
            );
            setIsClaimed(true);
            setHistory(response.data.transactionHash);
        } catch (error) {
            setClaimStatus("failed");
            console.log(error);
        }
    };

    const changeEmailVal = (e) => {
        setInputEmailValue(e.target.value);
    };

    const handleSaveEmail = async (ev) => {
        ev.preventDefault();
        try {
            const response = await axios.post(
                `${import.meta.env.VITE_API_BASE_LOCAL_DEV}/users/updateEmailAddress`,
                {
                    userId,
                    email: inputEmailValue,
                },
            );

            alert(`Response: ${response.data.message}`);
        } catch (error) {
            console.error("Error submitting data:", error);
            if (error.response) {
                alert(
                    `Error: ${error.response.status} - ${error.response.data.message}`,
                );
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    };

    return (
        <div className="account1 relative bg-primary-dark overflow-x-clip w-full">
            <section className="relative isolate overflow-visible">
                {/* Left gradient */}
                <div className="absolute -left-[35vw] sm:-left-[15vw] top-1/3 -translate-y-1/3 h-[120vh] sm:h-[80vh] w-[100vw] sm:w-[60vw] rounded-full bg-gradient-to-r from-[#1f3c88] via-[#1f3c88]/60 to-transparent opacity-50 sm:opacity-70 blur-[8vw] sm:blur-[6vw] -z-10 transform-gpu rotate-12 sm:rotate-0" />
                {/* Right gradient */}
                <div className="absolute -right-[35vw] sm:-right-[15vw] top-1/3 -translate-y-1/3 h-[120vh] sm:h-[80vh] w-[100vw] sm:w-[60vw] rounded-full bg-gradient-to-l from-[#4169e1] via-[#4169e1]/60 to-transparent opacity-60 sm:opacity-80 blur-[8vw] sm:blur-[6vw] -z-10 transform-gpu -rotate-12 sm:rotate-0" />
                <div className="relative z-0">
                    {/* <Mission />
                        <CoreValues /> */}
                </div>
            </section>

            <section className="relative isolate overflow-visible">
                {/* Top Left gradient */}
                <div className="absolute -left-[35vw] sm:-left-[15vw] top-60 h-[180vh] sm:h-[80vh] w-[100vw] sm:w-[60vw] rounded-full bg-gradient-to-r from-[#1f3c88] via-[#1f3c88]/60 to-transparent opacity-70 blur-[8vw] sm:blur-[6vw] -z-10 transform-gpu rotate-12 sm:rotate-0" />
                {/* Top Right gradient */}
                <div className="absolute -right-[35vw] sm:-right-[15vw] top-60 h-[180vh] sm:h-[80vh] w-[100vw] sm:w-[60vw] rounded-full bg-gradient-to-l from-[#4169e1] via-[#4169e1]/60 to-transparent opacity-80 blur-[8vw] sm:blur-[6vw] -z-10 transform-gpu -rotate-12 sm:rotate-0" />
                {/* Bottom Center gradient */}
                <div className="absolute left-1/2 -translate-x-1/2 bottom-0 h-[100vh] sm:h-[40vh] w-[120vw] sm:w-[80vw] rounded-full bg-gradient-to-t from-[#1f3c88] via-[#4169e1]/60 to-transparent opacity-60 blur-[10vw] sm:blur-[8vw] -z-10 transform-gpu" />
                <div className="relative z-0">
                    {/* <OurStory />
                        <Team /> */}
                    {/* <JoinUsCta /> */}
                    {!isConnected && !disconnect && !address ? (
                        <div className="mx-auto max-w-7xl px-6 pb-24 lg:px-8 min-h-24">
                            <div className="mx-auto max-w-2xl lg:max-w-4xl text-center min-h-80 ">
                                <TailSpin
                                    height="80"
                                    width="80"
                                    radius="1"
                                    color="white"
                                    ariaLabel="tail-spin-loading"
                                    wrapperStyle={{
                                        position: "absolute",
                                        left: "calc(50% - 40px)",
                                        minHeight: "70%",
                                        top: "50%",
                                    }}
                                    wrapperClass="align-middle"
                                />
                            </div>
                        </div>
                    ) : address && isConnected ? (
                        <div className="grid gap-10 grid-cols-8">
                            <div></div>
                            <div></div>

                            <div className="status-panel total">
                                <div></div>

                                <div className="display-block">
                                    <h4>
                                        Total UUC
                                        <span className="du-coin icon-coin-vertical-solid-1"></span>
                                    </h4>
                                    <span className="block-value">
                                        {data?.formatted}
                                    </span>
                                </div>
                            </div>
                            <div>
                                {/* Only one of these sections will be rendered based on the conditions */}
                                {!isClaimed && claimStatus === "" && (
                                    // Unclaimed Section
                                    <div>
                                        {eligibleResponse && (
                                            <div className="inline-block status-panel unclaimed">
                                                <div className="display-block">
                                                    <h4>Unclaimed UUC</h4>
                                                    <span className="block-value">
                                                        {isConnected ? (
                                                            <div>
                                                                {isClaimed ? (
                                                                    <div>
                                                                        <p>
                                                                            Claimed
                                                                            successfully
                                                                        </p>
                                                                    </div>
                                                                ) : eligibleResponse ? (
                                                                    <div>
                                                                        <p>
                                                                            {
                                                                                eligibleResponse.amount
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        ) : null}
                                                    </span>
                                                    {!isClaimed &&
                                                        eligibleResponse.message !==
                                                            "not eligible for any token claim" && (
                                                            <button
                                                                onClick={
                                                                    handleClaimClick
                                                                }
                                                            >
                                                                Claim
                                                            </button>
                                                        )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}

                                {claimStatus === "Processing" && (
                                    // Processing Section
                                    <div>
                                        <div className="inline-block status-panel unclaimed pending">
                                            <div className="display-block">
                                                <h4>Unclaimed UUC</h4>
                                                <span className="block-value">
                                                    {eligibleResponse.amount}
                                                </span>
                                                <button disabled>
                                                    Pending
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {!isClaimed &&
                                    eligibleResponse &&
                                    eligibleResponse.message !==
                                        "not eligible for any token claim" &&
                                    claimStatus === "failed" && (
                                        // Failed Section
                                        <div>
                                            <div className="inline-block status-panel unclaimed failed">
                                                <div className="display-block">
                                                    <h4>Unclaimed UUC</h4>
                                                    <span className="block-value">
                                                        {eligibleResponse
                                                            ? eligibleResponse.amount
                                                            : "No eligible response"}
                                                    </span>
                                                    <button
                                                        onClick={
                                                            handleClaimClick
                                                        }
                                                    >
                                                        Retry
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </div>

                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div className="status-panel table-view">
                                <div className="display-block">
                                    <h4>
                                        History
                                        <span className="du-coin icon-clock-rotate-left-solid-1"></span>
                                    </h4>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Event</th>
                                                {/* <th>From Address</th> */}
                                                <th>To Address</th>
                                                {/* <th>Email Address</th> */}
                                                {/* <th>Date</th> */}
                                                <th>Status</th>
                                                <th>Transaction Hash</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isClaimed && (
                                                <tr>
                                                    <td>claim</td>
                                                    <td>{address}</td>
                                                    <td>Done</td>
                                                    <td>{history}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div className="status-panel table-view connections">
                                <div className="display-block">
                                    <h4>
                                        Connected Accounts{" "}
                                        <span className="du-coin icon-link-solid-1"></span>
                                    </h4>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Connection</th>
                                                <th>Details</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Wallet</td>
                                                <td>{address}</td>
                                            </tr>
                                            <tr>
                                                <td>Email</td>
                                                <td>
                                                    {email && email !== "-" ? (
                                                        email
                                                    ) : (
                                                        <form
                                                            onSubmit={
                                                                handleSaveEmail
                                                            }
                                                        >
                                                            <input
                                                                type="text"
                                                                value={
                                                                    inputEmailValue
                                                                }
                                                                onChange={
                                                                    changeEmailVal
                                                                }
                                                                placeholder="Enter email"
                                                                style={{
                                                                    marginRight:
                                                                        "10px",
                                                                    color: "black",
                                                                }}
                                                            />
                                                            <input
                                                                type="submit"
                                                                value="Save"
                                                                style={{
                                                                    padding:
                                                                        "5px 15px",
                                                                    border: "2px solid white",
                                                                    borderRadius:
                                                                        "8px",
                                                                    cursor: "pointer",
                                                                }}
                                                            />
                                                        </form>
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div></div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </section>
        </div>
    );
};

export default AccountPage;
