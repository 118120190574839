export default function Mission() {
  return (
    <div className="pt-16 pb-24">
      <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
          <div className="px-6 lg:px-0 lg:pr-4">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
              <h2 className="text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl">
                Our Mission
              </h2>
              <p className="mt-4 text-xl/7 font-semibold text-amber-500 [text-shadow:0_0_7px_rgba(245,158,11,0.3),0_0_10px_rgba(245,158,11,0.2),0_0_21px_rgba(245,158,11,0.1)] transition-all duration-300 group-hover:[text-shadow:0_0_10px_rgba(245,158,11,0.4),0_0_14px_rgba(245,158,11,0.3),0_0_28px_rgba(245,158,11,0.2)]">
                Democratizing Political Influence
              </p>
              <p className="mt-8 text-xl/8 text-gray-300">
                The mission of USA Unity Coin is to democratize the influence of
                money in politics, unify America through civic engagement, and
                support the integrity of our political processes. <br></br>
                <br></br>By encouraging and empowering Americans to participate
                in a secure digital democracy, UUC strives to create a society
                where political transparency and civic responsibility are the
                cornerstones of governance.
              </p>
            </div>
          </div>
          <div className="px-6 lg:px-0 lg:pt-20">
            <div className="mx-auto max-w-2xl lg:max-w-lg">
              <img
                alt="Product screenshot"
                src="/Crowd.jpeg"
                className="w-full rounded-3xl"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
