import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import { useWallet } from "../contexts/WalletContext";
import { TailSpin } from "react-loader-spinner";

const VerifyEmailPage = ({ userId }) => {
    const params = useParams();
    const navigate = useNavigate();
    const { address, isConnected, setConnected, disconnect } = useWallet();


    useEffect( () => {
      console.log('userID', userId);


      const verifyEmail = async (userIdAwaited) => {
        console.log('verify', userIdAwaited, params.token);

        try {
          const response = await axios.post(
            `${import.meta.env.VITE_API_BASE_LOCAL_DEV}/users/verifyEmailAddress`,
            {
                userId: userIdAwaited,
                token: params.token,
            },
          );
  
          navigate("/account")
        } catch (error) {
            console.error("Error submitting data:", error);
            if (error.response) {
                console.error(
                    `Error: ${error.response.status} - ${error.response.data.message}`,
                );
            } else {
                console.error("An error occurred. Please try again.");
            }
        }
      }

      if (userId) {
        verifyEmail(userId);
      }
    }, [userId, params.token])

    return (

            <div className="mx-auto max-w-7xl px-6 pb-24 lg:px-8 min-h-24">
                <div className="mx-auto max-w-2xl lg:max-w-4xl text-center min-h-80 ">
                    <TailSpin
                        height="80"
                        width="80"
                        radius="1"
                        color="white"
                        ariaLabel="tail-spin-loading"
                        wrapperStyle={{
                            position: "absolute",
                            left: "calc(50% - 40px)",
                            minHeight: "70%",
                            top: "50%",
                        }}
                        wrapperClass="align-middle"
                    />
                </div>
            </div>
    );
};

export default VerifyEmailPage;
