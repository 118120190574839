export default function OurStory() {
  return (
    <div className="py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-5xl text-center">
          <h2 className="text-4xl font-semibold tracking-tight text-white sm:text-6xl">
            Our Story
          </h2>
          <p className="mt-4 text-xl/7 font-semibold text-amber-500 [text-shadow:0_0_7px_rgba(245,158,11,0.3),0_0_10px_rgba(245,158,11,0.2),0_0_21px_rgba(245,158,11,0.1)] transition-all duration-300 group-hover:[text-shadow:0_0_10px_rgba(245,158,11,0.4),0_0_14px_rgba(245,158,11,0.3),0_0_28px_rgba(245,158,11,0.2)]">
            From Vision to Action
          </p>
          <p className="mt-8 text-pretty text-xl/8 font-medium text-gray-300 sm:text-xl/8">
            Founded by blockchain enthusiasts and political strategists, USA
            Unity Coin was created to address the significant gaps in political
            funding and campaigning transparency. Our founders have been
            frustrated by the multitude of barriers that prevent everyday people
            from influencing political outcomes. We envision a platform that
            makes political engagement accessible to everyone.
          </p>
        </div>
      </div>
    </div>
  );
}
