export default function AboutHero() {
  return (
    <div className="py-24 sm:py-36 overflow-hidden relative isolate">
      {/* Gradient Mask - Fades to background */}
      <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-primary-dark -z-10" />

      {/* Gradient layers */}
      <div className="absolute inset-x-0 -top-20 transform-gpu overflow-hidden blur-3xl -z-20">
        <div className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#1f3c88] to-[#4169e1] opacity-10 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" />
      </div>
      <div className="absolute inset-x-0 -top-40 transform-gpu overflow-hidden blur-3xl -z-20">
        <div className="relative right-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] translate-x-1/2 rotate-[-10deg] bg-gradient-to-tr from-[#0a1c44] to-[#3457d1] opacity-30 sm:right-[calc(50%-36rem)] sm:w-[72.1875rem]" />
      </div>

      <div className="absolute inset-x-0 top-20 -z-20 transform-gpu overflow-hidden blur-3xl">
        <div className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#1f3c88] to-[#4169e1] opacity-10 sm:w-[72.1875rem]" />
      </div>
      <div className="absolute inset-x-0 top-40 -z-20 transform-gpu overflow-hidden blur-3xl">
        <div className="relative right-[calc(50%-3rem)] aspect-[1155/678] w-[36.125rem] translate-x-1/2 bg-gradient-to-tr from-[#0a1c44] to-[#3457d1] opacity-20 sm:w-[72.1875rem]" />
      </div>

      {/* Content */}
      <div className="mx-auto max-w-7xl mt-20 px-6 lg:px-8">
        <div className="mx-auto max-w-6xl text-center">
          <h1 className="text-balance text-5xl font-semibold tracking-tight text-white sm:text-7xl">
            Empowering Civic Engagement Through Cryptocurrency
          </h1>
          <p className="mt-8 mx-auto max-w-3xl text-pretty text-lg/8 font-medium text-gray-300 sm:text-xl/8">
            USA Unity Coin (UUC) is a revolution in how Americans engage with
            and influence the political landscape. UUC offers a unique platform
            where every transaction contributes to political advocacy and
            strengthening your portfolio. By integrating blockchain technology,
            UUC guarantees every contribution is secure and transparent,
            allowing users to support their causes directly.
          </p>
        </div>
      </div>
    </div>
  );
}
