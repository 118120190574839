import {
  ArrowPathRoundedSquareIcon,
  ScaleIcon,
  ShieldCheckIcon,
  CubeTransparentIcon,
  CheckCircleIcon,
} from "@heroicons/react/20/solid";

const benefits = [
  {
    name: "Advocate for transparent political communication",
    description: "",
    icon: CubeTransparentIcon,
  },
  {
    name: "Combat misinformation with verified data",
    description: "",
    icon: CheckCircleIcon,
  },
  {
    name: "Influence laws and policies through active engagement",
    description: "",
    icon: ScaleIcon,
  },
  {
    name: "Strengthen election integrity and blockchain security",
    description: "",
    icon: ShieldCheckIcon,
  },
];

export default function ClosingBenefits() {
  return (
    <div className="py-24 overflow-hidden relative">
      <div className="lg:col-span-3 px-6 lg:px-0 lg:pr-4 lg:pt-4">
        <div className="mx-auto max-w-7xl md:px-6 lg:px-8 relative">
          <div className="mx-auto max-w-3xl sm:text-center">
            <p className="text-xl/7 font-semibold text-amber-500 [text-shadow:0_0_7px_rgba(245,158,11,0.3),0_0_10px_rgba(245,158,11,0.2),0_0_21px_rgba(245,158,11,0.1)] transition-all duration-300 hover:[text-shadow:0_0_10px_rgba(245,158,11,0.4),0_0_14px_rgba(245,158,11,0.3),0_0_28px_rgba(245,158,11,0.2)]">
              USA Unity Coin
            </p>
            <h6 className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-white sm:text-balance sm:text-5xl">
              Drive Political Change with Every Transaction
            </h6>
            <p className="mt-6 text-xl/8 text-gray-300">
              Participate today to strengthen democracy, election integrity,
              advocate for transparent political disclosure and help enforce
              fairness in information exchange.
            </p>
          </div>
        </div>
        <div className="relative overflow-hidden pt-16">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <img
              alt="App screenshot"
              src="/AppScreenshot.png"
              width={2432}
              height={1442}
              className="mb-[-12%] rounded-xl blur-sm backdrop-blur-sm"
            />

            {/* <div aria-hidden="true" className="relative">
              <div className="absolute -inset-x-0 bottom-0 bg-gradient-to-t from-[#182C5D] to-transparent pt-[7%]" />
            </div> */}
          </div>
        </div>
        <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
          <dl className="mx-auto flex flex-col sm:grid sm:grid-cols-2 gap-x-10 gap-y-10 text-xl/7 text-gray-300 sm:max-w-3xl">
            {benefits.map((benefit) => (
              <div key={benefit.name} className="relative pl-12">
                <dt className="inline text-white">
                  <div className="absolute -left-1 top-1 rounded-lg p-1 transition-all duration-300 group-hover:bg-amber-500/5">
                    <benefit.icon
                      aria-hidden="true"
                      className="size-9 text-amber-500 [filter:drop-shadow(0_0_7px_rgba(245,158,11,0.3))_drop-shadow(0_0_10px_rgba(245,158,11,0.2))_drop-shadow(0_0_21px_rgba(245,158,11,0.1))] transition-all duration-300 group-hover:[filter:drop-shadow(0_0_10px_rgba(245,158,11,0.4))_drop-shadow(0_0_14px_rgba(245,158,11,0.3))_drop-shadow(0_0_28px_rgba(245,158,11,0.2))]"
                    />
                  </div>
                  {benefit.name}
                </dt>
                <dd className="inline">{benefit.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
