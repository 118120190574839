"use client";

import {
  CheckBadgeIcon,
  WalletIcon,
  UserGroupIcon,
} from "@heroicons/react/20/solid";

function StepNumber({ number }) {
  return (
    <div className="flex h-8 w-8 items-center justify-center rounded-lg bg-gray-900/50 ring-1 ring-amber-500/20">
      <span className="text-xl font-bold text-amber-500 [text-shadow:0_0_7px_rgba(245,158,11,0.3),0_0_10px_rgba(245,158,11,0.2),0_0_21px_rgba(245,158,11,0.1)] transition-all duration-300 group-hover:[text-shadow:0_0_10px_rgba(245,158,11,0.4),0_0_14px_rgba(245,158,11,0.3),0_0_28px_rgba(245,158,11,0.2)]">
        {number}
      </span>
    </div>
  );
}

function Step({ step, number }) {
  return (
    <div className="group relative flex flex-col rounded-2xl backdrop-blur-xl p-8 transition-all duration-300">
      {/* Base glass effect */}
      <div className="absolute inset-0 rounded-2xl bg-gradient-to-br from-white/[0.075] via-white/[0.05] to-transparent" />

      {/* Hover with blue tint */}
      <div className="absolute inset-0 rounded-2xl bg-gradient-to-br from-blue-500/[0.15] via-blue-400/[0.1] to-blue-300/[0.05] opacity-0 group-hover:opacity-100 transition-all duration-300" />

      {/* Glow effect on hover */}
      <div className="absolute inset-0 rounded-2xl shadow-[0_0_15px_rgba(59,130,246,0)] group-hover:shadow-[0_0_15px_rgba(59,130,246,0.15)] transition-all duration-300" />

      {/* Border */}
      <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-700/50 group-hover:ring-blue-500/30 transition-all duration-300" />

      <div className="relative">
        <dt className="flex items-center gap-x-3 text-2xl/8 font-semibold text-amber-500">
          <StepNumber number={number} />
          <span className="[text-shadow:0_0_7px_rgba(245,158,11,0.3),0_0_10px_rgba(245,158,11,0.2),0_0_21px_rgba(245,158,11,0.1)] transition-all duration-300 group-hover:[text-shadow:0_0_10px_rgba(245,158,11,0.4),0_0_14px_rgba(245,158,11,0.3),0_0_28px_rgba(245,158,11,0.2)]">
            {step.name}
          </span>
        </dt>
        <dd className="mt-4 flex flex-auto flex-col text-xl/8 text-gray-300">
          <p className="flex-auto">{step.description}</p>
        </dd>
      </div>
    </div>
  );
}

const steps = [
  {
    name: "Register and Verify",
    description:
      "Begin by registering here on our official platform. This initial step gives you early access to features and prepares you for active participation.",
    icon: CheckBadgeIcon,
  },
  {
    name: "Acquire UUC",
    description:
      "Once registered, you can receive USA Unity Coins as a symbolic token of your donation directly through our platform.",
    icon: WalletIcon,
  },
  {
    name: "Participate and Influence",
    description:
      "Engage directly in shaping the future by using your UUC to symbolize your support for key initiatives, participate in polls, and contribute to decisions aligned with American values. Each action you take reflects your commitment to our shared mission and amplifies the impact of your contribution.",
    icon: UserGroupIcon,
  },
];

export default function HowItWorks() {
  return (
    <div className="py-24 overflow-hidden relative">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 relative z-10">
        <div className="mx-auto max-w-4xl lg:text-center">
          <p className="text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl lg:text-balance">
            Unleash Your Influence Over Political and Financial Contributions
          </p>
          <p className="mt-20 text-4xl text-gray-300">Here's How It Works…</p>
        </div>
        <div className="mx-auto mt-8 max-w-4xl sm:mt-16 lg:mt-10">
          <div className="flex flex-col gap-6">
            {steps.map((step, index) => (
              <Step key={step.name} step={step} number={index + 1} />
            ))}
          </div>
        </div>

        {/* <div className="mt-12 text-center relative">
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[400px] h-[150px] rounded-full bg-gradient-to-r from-[#1f3c88] via-[#4169e1] to-[#4169e1] opacity-40 blur-3xl -z-10" />

          <a
            href="#"
            className="inline-block rounded-md bg-white/10 px-8 py-2.5 text-lg font-semibold text-white shadow-[0_0_15px_rgba(255,255,255,0.1)] transition-all hover:bg-blue-400/30 hover:shadow-[0_0_20px_rgba(96,165,250,0.4)] relative group border border-white/10"
          >
            <span className="absolute -inset-0.5 rounded-lg bg-gradient-to-r from-blue-200/20 via-blue-200/30 to-blue-200/20 opacity-0 transition-opacity group-hover:opacity-40 blur" />
            <span className="relative">Get Started</span>
          </a>
        </div> */}
      </div>
    </div>
  );
}
