import { CheckIcon } from "@heroicons/react/20/solid";

const features = [
  {
    name: "Transparency",
    description: "Open and accountable processes in every transaction.",
  },
  {
    name: "Empowerment",
    description:
      "Equipping users with the tools to influence and engage effectively.",
  },
  {
    name: "Integrity",
    description:
      "Upholding ethical standards in all our operations and offerings.",
  },
  {
    name: "Civic Engagement",
    description: "Facilitating active participation in democratic processes.",
  },
  {
    name: "Social Responsibility",
    description: "Committing to societal impact through shared values.",
  },
];

export default function CoreValues() {
  return (
    <div className="py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          <div className="col-span-2">
            <h2 className="text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl">
              Our Core Values
            </h2>
            <p className="mt-4 text-xl/7 font-semibold text-amber-500 [text-shadow:0_0_7px_rgba(245,158,11,0.3),0_0_10px_rgba(245,158,11,0.2),0_0_21px_rgba(245,158,11,0.1)] transition-all duration-300 group-hover:[text-shadow:0_0_10px_rgba(245,158,11,0.4),0_0_14px_rgba(245,158,11,0.3),0_0_28px_rgba(245,158,11,0.2)]">
              Guided by Integrity and Impact
            </p>
            <div className="relative mt-16 w-80 overflow-hidden rounded-2xl">
              <img
                src="/white-uuc.png"
                alt="Core Values"
                className="w-full object-cover mix-blend-screen opacity-20"
              />
            </div>
          </div>
          <dl className="col-span-3 grid grid-cols-1 gap-x-8 gap-y-10 text-xl/7 text-gray-300 sm:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="group relative pl-12">
                <dt className="font-semibold text-amber-500">
                  <CheckIcon
                    aria-hidden="true"
                    className="absolute left-0 top-1 size-7 text-amber-500 [filter:drop-shadow(0_0_7px_rgba(245,158,11,0.3))_drop-shadow(0_0_10px_rgba(245,158,11,0.2))_drop-shadow(0_0_21px_rgba(245,158,11,0.1))] transition-all duration-300 group-hover:[filter:drop-shadow(0_0_10px_rgba(245,158,11,0.4))_drop-shadow(0_0_14px_rgba(245,158,11,0.3))_drop-shadow(0_0_28px_rgba(245,158,11,0.2))]"
                  />
                  <span className="[text-shadow:0_0_7px_rgba(245,158,11,0.3),0_0_10px_rgba(245,158,11,0.2),0_0_21px_rgba(245,158,11,0.1)] transition-all duration-300 group-hover:[text-shadow:0_0_10px_rgba(245,158,11,0.4),0_0_14px_rgba(245,158,11,0.3),0_0_28px_rgba(245,158,11,0.2)]">
                    {feature.name}
                  </span>
                </dt>
                <dd className="mt-2 text-gray-300">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
