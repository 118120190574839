export default function HeroLeft() {
  return (
    <div className="py-24 sm:py-44 overflow-hidden relative">
      <div className="absolute inset-0 -z-20">
        <div className="relative w-full h-full">
          <div className="absolute inset-0 bg-primary-dark" />
          <video
            autoPlay
            loop
            muted
            playsInline
            preload="auto"
            className="w-full h-full object-cover mix-blend-screen opacity-50"
          >
            <source src="/videos/bg-particles.mp4" type="video/mp4" />
          </video>
        </div>
      </div>

      {/* Gradient layers */}
      <div className="absolute inset-x-0 bottom-20 transform-gpu overflow-hidden blur-3xl -z-10">
        <div className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#1f3c88] via-[#B40E0A]/40 to-[#4169e1] opacity-10 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" />
      </div>
      <div className="absolute inset-x-0 bottom-40 transform-gpu overflow-hidden blur-3xl -z-10">
        <div className="relative right-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] translate-x-1/2 rotate-[-10deg] bg-gradient-to-tr from-[#0a1c44] via-[#B40E0A]/35 to-[#3457d1] opacity-30 sm:right-[calc(50%-36rem)] sm:w-[72.1875rem]" />
      </div>

      <div className="absolute inset-x-0 top-20 -z-10 transform-gpu overflow-hidden blur-3xl">
        <div className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#1f3c88] to-[#4169e1] opacity-10 sm:w-[72.1875rem]" />
      </div>
      <div className="absolute inset-x-0 top-40 -z-10 transform-gpu overflow-hidden blur-3xl">
        <div className="relative right-[calc(50%-3rem)] aspect-[1155/678] w-[36.125rem] translate-x-1/2 bg-gradient-to-tr from-[#0a1c44] via-[#B40E0A]/30 to-[#3457d1] opacity-20 sm:w-[72.1875rem]" />
      </div>

      {/* New floating red gradient */}
      <div className="absolute right-1/4 top-1/3 transform-gpu overflow-hidden blur-3xl -z-10">
        <div className="relative aspect-[1155/678] w-[36.125rem] rotate-[45deg] bg-gradient-to-br from-[#B40E0A]/30 via-[#B40E0A]/20 to-transparent opacity-20 sm:w-[50rem]" />
      </div>

      {/* Content */}
      <div className="relative z-10 mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-10 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
            <h1 className="mt-10 text-pretty text-5xl font-semibold tracking-tight text-white sm:text-7xl">
              Take Control of Your Political Impact with UUC
            </h1>
            <p className="mt-8 text-pretty text-lg/8 font-medium text-gray-300 sm:text-xl/8">
              Elevate your portfolio while engaging directly in the political
              process with the USA Unity Coin. This innovative platform is
              designed to allow transparent and immutable exchanges through
              blockchain between public and political figures.
            </p>

            <div className="mt-10 flex gap-x-6">
              {/* <a
                href="#"
                className="relative rounded-md bg-amber-500 px-6 py-2.5 text-lg font-semibold text-gray-900 shadow-[0_0_15px_rgba(251,191,36,0.5)] transition-all hover:bg-amber-400 hover:shadow-[0_0_25px_rgba(251,191,36,0.65)] focus:outline-none focus:ring-2 focus:ring-amber-400 focus:ring-offset-2 focus:ring-offset-gray-900 group"
              >
                <span className="absolute -inset-0.5 rounded-lg bg-gradient-to-r from-amber-500 via-amber-400 to-amber-500 opacity-0 transition-opacity group-hover:opacity-30 blur" />
                <span className="relative">Buy UUC</span>
              </a> */}

              <a
                href="/usa_unity_coin_whitepaper.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="relative rounded-md bg-amber-500 px-8 py-2.5 text-lg font-semibold text-gray-900 shadow-[0_0_15px_rgba(251,191,36,0.5)] transition-all hover:bg-amber-400 hover:shadow-[0_0_25px_rgba(251,191,36,0.65)] focus:outline-none focus:ring-2 focus:ring-amber-400 focus:ring-offset-2 focus:ring-offset-gray-900 group"
              >
                <span className="absolute -inset-0.5 rounded-lg bg-gradient-to-r from-amber-500 via-amber-400 to-amber-500 opacity-0 transition-opacity group-hover:opacity-30 blur" />
                <span className="relative">Read the Whitepaper</span>
              </a>

              {/* <a
                href="/usa_unity_coin_whitepaper.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block rounded-md bg-white/10 px-8 py-2.5 text-lg font-semibold text-white shadow-[0_0_15px_rgba(255,255,255,0.1)] transition-all hover:bg-blue-400/30 hover:shadow-[0_0_20px_rgba(96,165,250,0.4)] relative group border border-white/10"
              >
                <span className="absolute -inset-0.5 rounded-lg bg-gradient-to-r from-blue-200/20 via-blue-200/30 to-blue-200/20 opacity-0 transition-opacity group-hover:opacity-40 blur" />
                <span className="relative">Read the Whitepaper</span>
              </a> */}
              {/* <a
                href="#"
                className="inline-block rounded-md bg-white/10 px-8 py-2.5 text-lg font-semibold text-white shadow-[0_0_15px_rgba(255,255,255,0.1)] transition-all hover:bg-blue-400/30 hover:shadow-[0_0_20px_rgba(96,165,250,0.4)] relative group border border-white/10"
              >
                <span className="absolute -inset-0.5 rounded-lg bg-gradient-to-r from-blue-200/20 via-blue-200/30 to-blue-200/20 opacity-0 transition-opacity group-hover:opacity-40 blur" />
                <span className="relative">Register for Early Access</span>
              </a> */}
            </div>
          </div>
          <img
            alt="Product screenshot"
            src="/AppScreenshot.png"
            width={2432}
            height={1442}
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-white/10 sm:w-[57rem] md:-ml-4 lg:-ml-0 blur-sm backdrop-blur-sm"
          />
        </div>
      </div>
    </div>
  );
}
