const people = [
  {
    name: "Alex Lightman",
    role: "CEO",
    imageUrl: "/Alex.jpg",
    bio: "CEO and visionary behind USA Unity Coin, Alex Lightman brings a wealth of experience in blockchain technology and international policy to the table. As a renowned expert, he has advised the U.S. Congress, the Department of State, and the Department of Defense, leveraging his deep knowledge to drive UUC's mission of integrating blockchain into the political process. Alex has committed $1 million to fund the initial costs of the UUC project, ensuring it has the resources needed to succeed.",
    xUrl: "#",
    linkedinUrl: "#",
  },
  {
    name: "Confidential Team",
    role: "Executives and Advisors",
    imageUrl: "/uuc-tall-bg.png",
    bio: "Supporting the core leadership is a confidential team of executives and advisors from the crypto-community, political leadership, and traditional financial markets. This team's impressive background includes pivotal roles in high-profile crypto launches and strategic transitions. Their combined experience and industry connections are invaluable, positioning UUC for a successful launch while pushing the vision further.",
    xUrl: "#",
    linkedinUrl: "#",
  },
];

export default function Team() {
  return (
    <div className="mx-auto max-w-7xl px-6 pb-24 lg:px-8">
      <div className="mx-auto max-w-2xl lg:max-w-4xl text-center">
        <h2 className="text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl">
          Meet Our Team
        </h2>
      </div>

      <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:max-w-5xl lg:grid-cols-2">
        {people.map((person) => (
          <div
            key={person.name}
            className="group relative flex flex-col gap-6 rounded-2xl backdrop-blur-xl p-8 transition-all duration-300"
          >
            {/* Base glass effect */}
            <div className="absolute inset-0 rounded-2xl bg-gradient-to-br from-white/[0.075] via-white/[0.05] to-transparent" />

            {/* Hover with blue tint */}
            <div className="absolute inset-0 rounded-2xl bg-gradient-to-br from-blue-500/[0.15] via-blue-400/[0.1] to-blue-300/[0.05] opacity-0 group-hover:opacity-100 transition-all duration-300" />

            {/* Glow effect on hover */}
            <div className="absolute inset-0 rounded-2xl shadow-[0_0_15px_rgba(59,130,246,0)] group-hover:shadow-[0_0_15px_rgba(59,130,246,0.15)] transition-all duration-300" />

            {/* Border */}
            <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-700/50 group-hover:ring-blue-500/30 transition-all duration-300" />

            <div className="relative">
              <img
                alt=""
                src={person.imageUrl}
                className="aspect-[4/5] w-52 flex-none rounded-2xl object-cover"
              />
              <div className="mt-6 flex-auto">
                <h3 className="text-2xl/8 font-semibold tracking-tight text-amber-500 [text-shadow:0_0_7px_rgba(245,158,11,0.3),0_0_10px_rgba(245,158,11,0.2),0_0_21px_rgba(245,158,11,0.1)] transition-all duration-300 group-hover:[text-shadow:0_0_10px_rgba(245,158,11,0.4),0_0_14px_rgba(245,158,11,0.3),0_0_28px_rgba(245,158,11,0.2)]">
                  {person.name}
                </h3>
                <p className="text-xl/7 text-gray-300">{person.role}</p>
                <p className="mt-6 text-xl/7 text-gray-300">{person.bio}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
