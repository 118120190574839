import React from "react";
import AboutHero from "../components/About/AboutHero";
import Mission from "../components/About/Mission";
import CoreValues from "../components/About/CoreValues";
import OurStory from "../components/About/OurStory";
import Team from "../components/About/Team";
import JoinUsCta from "../components/About/JoinUsCta";

const About = () => {
  return (
    <div className="relative bg-primary-dark overflow-x-clip w-full">
      <AboutHero />

      <section className="relative isolate overflow-visible">
        {/* Left gradient */}
        <div className="absolute -left-[35vw] sm:-left-[15vw] top-1/3 -translate-y-1/3 h-[120vh] sm:h-[80vh] w-[100vw] sm:w-[60vw] rounded-full bg-gradient-to-r from-[#1f3c88] via-[#1f3c88]/60 to-transparent opacity-50 sm:opacity-70 blur-[8vw] sm:blur-[6vw] -z-10 transform-gpu rotate-12 sm:rotate-0" />
        {/* Right gradient */}
        <div className="absolute -right-[35vw] sm:-right-[15vw] top-1/3 -translate-y-1/3 h-[120vh] sm:h-[80vh] w-[100vw] sm:w-[60vw] rounded-full bg-gradient-to-l from-[#4169e1] via-[#4169e1]/60 to-transparent opacity-60 sm:opacity-80 blur-[8vw] sm:blur-[6vw] -z-10 transform-gpu -rotate-12 sm:rotate-0" />
        <div className="relative z-0">
          <Mission />
          <CoreValues />
        </div>
      </section>

      <section className="relative isolate overflow-visible">
        {/* Top Left gradient */}
        <div className="absolute -left-[35vw] sm:-left-[15vw] top-60 h-[180vh] sm:h-[80vh] w-[100vw] sm:w-[60vw] rounded-full bg-gradient-to-r from-[#1f3c88] via-[#1f3c88]/60 to-transparent opacity-70 blur-[8vw] sm:blur-[6vw] -z-10 transform-gpu rotate-12 sm:rotate-0" />
        {/* Top Right gradient */}
        <div className="absolute -right-[35vw] sm:-right-[15vw] top-60 h-[180vh] sm:h-[80vh] w-[100vw] sm:w-[60vw] rounded-full bg-gradient-to-l from-[#4169e1] via-[#4169e1]/60 to-transparent opacity-80 blur-[8vw] sm:blur-[6vw] -z-10 transform-gpu -rotate-12 sm:rotate-0" />
        {/* Bottom Center gradient */}
        <div className="absolute left-1/2 -translate-x-1/2 bottom-0 h-[100vh] sm:h-[40vh] w-[120vw] sm:w-[80vw] rounded-full bg-gradient-to-t from-[#1f3c88] via-[#4169e1]/60 to-transparent opacity-60 blur-[10vw] sm:blur-[8vw] -z-10 transform-gpu" />
        <div className="relative z-0">
          <OurStory />
          <Team />
          {/* <JoinUsCta /> */}
        </div>
      </section>
    </div>
  );
};

export default About;
