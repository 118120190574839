import { MegaphoneIcon, LinkIcon, EyeIcon } from "@heroicons/react/20/solid";

const groups = [
  {
    name: "Civic-Minded Activist",
    description:
      "If you are new to blockchain technology and desire a platform where your voice and contributions truly matter, UUC is your tool for change. \n\n Engage directly in campaigns, influence legislation, and see the tangible effects of your advocacy, all through a transparent and secure blockchain-based platform.",
    icon: MegaphoneIcon,
  },
  {
    name: "Visionary Innovators",
    description:
      "Visionaries seeking to drive progress at the intersection of technology and society will find a powerful ally in UUC. \n\n Our platform leverages cutting-edge blockchain technology to ensure every transaction promotes transparency and integrity within the political funding process, paving the way for a more accountable and participatory democracy.",
    icon: EyeIcon,
  },
  {
    name: "Blockchain Enthusiasts",
    description:
      "Are you passionate about leveraging blockchain technology for real-world applications? \n\n USA Unity Coin is designed for enthusiasts who see beyond the crypto market to its potential for profound societal impact. With UUC, your participation offers a unique opportunity to contribute to both civic empowerment and social innovation.",
    icon: LinkIcon,
  },
];

export default function Empowering() {
  return (
    <div className="py-24 overflow-hidden relative">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 relative z-10">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl text-center">
            Empowering Activists, Visionaries, Blockchain Enthusiasts
          </h2>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-16 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 mx-auto lg:max-w-none lg:grid-cols-3">
            {groups.map((group) => (
              <div
                key={group.name}
                className="group relative flex flex-col rounded-2xl backdrop-blur-xl p-6 transition-all duration-300 max-w-xl mx-auto w-full"
              >
                {/* Base glass effect */}
                <div className="absolute inset-0 rounded-2xl bg-gradient-to-br from-white/[0.075] via-white/[0.05] to-transparent" />

                {/* Hover with blue tint */}
                <div className="absolute inset-0 rounded-2xl bg-gradient-to-br from-blue-500/[0.15] via-blue-400/[0.1] to-blue-300/[0.05] opacity-0 group-hover:opacity-100 transition-all duration-300" />

                {/* Glow effect on hover */}
                <div className="absolute inset-0 rounded-2xl shadow-[0_0_15px_rgba(59,130,246,0)] group-hover:shadow-[0_0_15px_rgba(59,130,246,0.15)] transition-all duration-300" />

                {/* Border */}
                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-700/50 group-hover:ring-blue-500/30 transition-all duration-300" />

                <dt className="relative flex items-center gap-x-3 text-xl/8 font-semibold text-amber-500">
                  <div className="rounded-lg bg-gray-900/50 p-2 ring-1 ring-amber-500/20 transition-all duration-300 group-hover:ring-amber-500/40">
                    <group.icon
                      aria-hidden="true"
                      className="size-5 flex-none text-amber-500 [filter:drop-shadow(0_0_7px_rgba(245,158,11,0.3))_drop-shadow(0_0_10px_rgba(245,158,11,0.2))_drop-shadow(0_0_21px_rgba(245,158,11,0.1))] transition-all duration-300 group-hover:[filter:drop-shadow(0_0_10px_rgba(245,158,11,0.4))_drop-shadow(0_0_14px_rgba(245,158,11,0.3))_drop-shadow(0_0_28px_rgba(245,158,11,0.2))]"
                    />
                  </div>
                  <span className="[text-shadow:0_0_7px_rgba(245,158,11,0.3),0_0_10px_rgba(245,158,11,0.2),0_0_21px_rgba(245,158,11,0.1)] transition-all duration-300 group-hover:[text-shadow:0_0_10px_rgba(245,158,11,0.4),0_0_14px_rgba(245,158,11,0.3),0_0_28px_rgba(245,158,11,0.2)]">
                    {group.name}
                  </span>
                </dt>
                <dd className="relative mt-4 flex flex-auto flex-col text-xl/8 text-gray-300">
                  <p className="flex-auto whitespace-pre-line">
                    {group.description}
                  </p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
