import React from "react";
import HeroLeft from "../components/LandingPage/HeroLeft";
import TelegramInvite from "../components/LandingPage/TelegramInvite";
import TimeForChange from "../components/LandingPage/TimeForChange";
import HowItWorks from "../components/LandingPage/HowItWorks";
import Benefits from "../components/LandingPage/Benefits";
// import GetInvolved from "../components/LandingPage/GetInvolved";
import Empowering from "../components/LandingPage/Empowering";
import Leadership from "../components/LandingPage/Leadership";
import ClosingBenefits from "../components/LandingPage/ClosingBenefits";
// import SubscribeNewsletter from "../components/LandingPage/SubscribeNewsletter";
import DisclaimerText from "../components/LandingPage/DisclaimerText";

const LandingPage = () => {
  return (
    <div className="relative overflow-x-clip w-full">
      <HeroLeft />
      <TelegramInvite />
      <section className="relative">
        <div className="absolute -left-[35vw] sm:-left-[15vw] top-1/3 -translate-y-1/3 h-[120vh] sm:h-[80vh] w-[100vw] sm:w-[60vw] rounded-full bg-gradient-to-r from-[#1f3c88] via-[#B40E0A]/35 to-transparent opacity-50 sm:opacity-70 blur-[8vw] sm:blur-[6vw] -z-10 transform-gpu rotate-12 sm:rotate-0" />
        <div className="absolute -right-[35vw] sm:-right-[15vw] top-1/3 -translate-y-1/3 h-[120vh] sm:h-[80vh] w-[100vw] sm:w-[60vw] rounded-full bg-gradient-to-l from-[#4169e1] via-[#4169e1]/60 to-transparent opacity-60 sm:opacity-80 blur-[8vw] sm:blur-[6vw] -z-10 transform-gpu -rotate-12 sm:rotate-0" />
        <div className="absolute left-1/2 top-1/4 -translate-x-1/2 h-[40vh] w-[40vw] rounded-full bg-gradient-to-br from-[#B40E0A]/40 via-[#B40E0A]/25 to-transparent opacity-50 blur-[10vw] -z-10 transform-gpu animate-pulse" />
        <div className="relative z-0">
          <TimeForChange />
          <HowItWorks />
        </div>
      </section>
      <section className="relative">
        <div className="absolute -left-[35vw] sm:-left-[15vw] top-[20vh] h-[130vh] sm:h-[90vh] w-[110vw] sm:w-[70vw] rounded-full bg-gradient-to-r from-[#1f3c88] via-[#1f3c88]/60 to-transparent opacity-70 blur-[8vw] sm:blur-[6vw] -z-10 origin-top scale-x-75 scale-y-125 sm:scale-y-110 transform-gpu rotate-6 sm:rotate-0" />
        <div className="absolute -right-[35vw] sm:-right-[15vw] top-[20vh] h-[130vh] sm:h-[90vh] w-[110vw] sm:w-[70vw] rounded-full bg-gradient-to-l from-[#4169e1] via-[#B40E0A]/35 to-transparent opacity-60 blur-[8vw] sm:blur-[6vw] -z-10 origin-bottom scale-x-75 scale-y-125 sm:scale-y-110 transform-gpu -rotate-6 sm:rotate-0" />
        <div className="relative z-0">
          <Benefits />
        </div>
      </section>
      <section className="relative">
        <div className="absolute -left-[35vw] sm:-left-[15vw] top-0 h-[180vh] sm:h-[80vh] w-[100vw] sm:w-[60vw] rounded-full bg-gradient-to-r from-[#1f3c88] via-[#B40E0A]/45 to-transparent opacity-70 blur-[8vw] sm:blur-[6vw] -z-10 transform-gpu rotate-12 sm:rotate-0" />
        <div className="absolute -right-[35vw] sm:-right-[15vw] top-0 h-[180vh] sm:h-[80vh] w-[100vw] sm:w-[60vw] rounded-full bg-gradient-to-l from-[#4169e1] via-[#4169e1]/60 to-transparent opacity-80 blur-[8vw] sm:blur-[6vw] -z-10 transform-gpu -rotate-12 sm:rotate-0" />
        <div className="absolute left-1/2 -translate-x-1/2 bottom-0 h-[100vh] sm:h-[40vh] w-[120vw] sm:w-[80vw] rounded-full bg-gradient-to-t from-[#1f3c88] via-[#B40E0A]/35 to-transparent opacity-60 blur-[10vw] sm:blur-[8vw] -z-10 transform-gpu" />
        <div className="relative z-0">
          {/* <GetInvolved /> */}
          <Empowering />
        </div>
      </section>
      <section className="relative overflow-hidden">
        <div className="absolute left-1/2 -translate-x-1/2 top-80 h-[120vh] sm:h-[40vh] w-[150vw] sm:w-[90vw] rounded-full bg-gradient-to-tl from-[#1f3c88] via-[#B40E0A]/45 to-transparent opacity-70 blur-[10vw] sm:blur-[8vw] -z-10 origin-center -rotate-[18deg] sm:-rotate-12 transform-gpu" />
        <div className="absolute -right-[20vw] top-40 h-[60vh] w-[40vw] rounded-full bg-gradient-to-bl from-[#B40E0A]/30 via-[#B40E0A]/35 to-transparent opacity-60 blur-[12vw] -z-10 transform-gpu rotate-45" />
        <div className="absolute left-1/2 -translate-x-1/6 bottom-20 h-[10vh] w-[150vw] rounded-full bg-gradient-to-t from-[#1f3c88] via-[#4169e1]/60 to-transparent opacity-50 blur-[10vw] -z-10 transform-gpu sm:hidden" />
        <div className="relative z-0">
          <Leadership />
        </div>
      </section>
      <section className="relative">
        <div className="absolute -left-[35vw] sm:-left-[15vw] top-1/3 h-[80vh] sm:h-[50vh] w-[100vw] sm:w-[60vw] rounded-full bg-gradient-to-r from-[#B40E0A]/45 via-[#1f3c88]/60 to-transparent opacity-70 blur-[8vw] sm:blur-[6vw] -z-10 transform-gpu rotate-12 sm:rotate-0" />
        <div className="absolute -right-[35vw] sm:-right-[15vw] top-1/3 h-[80vh] sm:h-[50vh] w-[100vw] sm:w-[60vw] rounded-full bg-gradient-to-l from-[#4169e1] via-[#4169e1]/60 to-transparent opacity-80 blur-[8vw] sm:blur-[6vw] -z-10 transform-gpu -rotate-12 sm:rotate-0" />
        <div className="absolute left-1/2 -translate-x-1/2 bottom-0 h-[60vh] sm:h-[40vh] w-[120vw] sm:w-[80vw] rounded-full bg-gradient-to-b from-[#111929] via-[#B40E0A]/35 to-[#4169e1] opacity-60 blur-[10vw] sm:blur-[8vw] -z-10 transform-gpu" />
        <div className="relative z-0">
          <ClosingBenefits />
        </div>
      </section>
      <section className="relative">
        <div className="absolute left-1/2 -translate-x-1/2 top-0 h-[40vh] sm:h-[40vh] w-[40vw] rounded-full bg-gradient-to-b from-transparent via-[#B40E0A]/40 to-transparent opacity-60 blur-[8vw] sm:blur-[6vw] -z-10 transform-gpu" />
        <div className="absolute top-1/4 -translate-y-1/2 left-0 h-[10vh] sm:h-[10vh] w-[100vw] rounded-full bg-gradient-to-r from-transparent via-[#1f3c88]/60 to-transparent opacity-60 blur-[8vw] sm:blur-[6vw] -z-10 transform-gpu" />
        <div className="relative z-0">
          <DisclaimerText />
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
