"use client";

import React, { useState } from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HeaderNav from "./components/HeaderNav";
import LandingPage from "./pages/LandingPage";
import About from "./pages/About";
import { OnboardingPage } from "./pages/OnboardingPage";
import AccountPage from "./pages/AccountPage";
import VerifyEmailPage from "./pages/VerifyEmailPage";
import { TokenSalePage } from "./pages/TokenSalePage";
import { SignupPage } from "./pages/SignupPage";
import { LoginPage } from "./pages/LoginPage";
import Footer from "./components/Footer";

import { useAppKitAccount } from "@reown/appkit/react";
function App() {
    // const { address, isConnected, status } = useAppKitAccount(); // Destructure to get address, isConnected, and status
    const [userId, setUserId] = useState(localStorage.getItem("userId") || ""); // Shared state
    return (
        <Router>
            <HeaderNav userId={userId} setUserId={setUserId} />
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/about" element={<About />} />
                <Route
                    path="/account"
                    element={<AccountPage userId={userId} />}
                />
                <Route path="/token-sale" element={<TokenSalePage />} />
                <Route path="/signup" element={<SignupPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/verify-email/:token" element={<VerifyEmailPage userId={userId}  />} />
            </Routes>
            <Footer />
        </Router>
    );
}

export default App;
