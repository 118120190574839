import React, { createContext, useContext, useState, useEffect } from "react";
import { useAppKitAccount } from "@reown/appkit/react";

// Create Context
const WalletContext = createContext();

// Provider Component
export function WalletProvider({ children }) {
    const { address, isConnected, status, ...methods } = useAppKitAccount();
    const [connected, setConnected] = useState(
        () => JSON.parse(localStorage.getItem("isConnected")) || false,
    );

    // Synchronize connection state with localStorage
    useEffect(() => {
        localStorage.setItem(
            "isConnected",
            JSON.stringify(isConnected || connected),
        );
    }, [isConnected, connected]);

    return (
        <WalletContext.Provider
            value={{ address, isConnected, status, connected, setConnected }}
        >
            {children}
        </WalletContext.Provider>
    );
}

// Custom Hook for Accessing Context
export function useWallet() {
    return useContext(WalletContext);
}
