import { createAppKit, useAppKitTheme } from "@reown/appkit/react";
import { WagmiProvider } from "wagmi";
import { base } from "@reown/appkit/networks";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiAdapter } from "@reown/appkit-adapter-wagmi";

// Setup queryClient
const queryClient = new QueryClient();

// projectId from https://cloud.reown.com (can change later)
const projectId = import.meta.env.VITE_REOWN_PROJECT_ID;
// Set the networks
const networks = [base];

// Create Wagmi Adapter
const wagmiAdapter = new WagmiAdapter({
    networks,
    projectId,
    ssr: true,
});

// Create modal
createAppKit({
    adapters: [wagmiAdapter],
    featuredWalletIds: [
        "fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa",
    ],
    networks,
    projectId,
    themeVariables: {
        "--w3m-accent": "#000080",
    },
});

export function AppKitProvider({ children }) {
    return (
        <WagmiProvider config={wagmiAdapter.wagmiConfig}>
            <QueryClientProvider client={queryClient}>
                {children}
            </QueryClientProvider>
        </WagmiProvider>
    );
}
