export default function Benefits() {
  return (
    <div className="py-24 overflow-hidden relative">
      <div className="mx-auto max-w-7xl px-4 md:px-6 lg:px-8 relative">
        {/* First Section */}
        <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-center">
          <div className="px-0 lg:px-0">
            <div className="-mx-4 sm:mx-0 lg:px-0">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                <img
                  alt="Product screenshot"
                  src="/CapBuilding.jpg"
                  width={2432}
                  height={1442}
                  className="w-full lg:w-[88%] rounded-none sm:rounded-3xl"
                />
              </div>
            </div>
          </div>
          <div className="lg:pr-4">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
              <p className="text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl">
                Make a Truly Impactful Contribution
              </p>
              <p className="mt-8 text-xl/8 text-gray-300">
                USA Unity Coin transcends fleeting meme coins by offering a
                trusted and impactful contribution. Backed by the strategic
                guidance of blockchain expert Alex Lightman, UUC provides a
                tangible reward for your contribution.
              </p>
            </div>
          </div>
        </div>

        <div className="mt-16">
          <div className="mx-auto max-w-2xl lg:max-w-none">
            <p className="text-xl/8 text-gray-300">
              By contributing to the USA Unity Coin, you will receive UUC tokens
              as a reward, enabling you to actively participate in our mission.
              This structured approach supports operational sustainability and
              ensures resources are allocated effectively for impactful
              initiatives.
            </p>
          </div>
        </div>

        {/* Second Section */}
        <div className="mt-32 grid grid-cols-1 gap-x-8 gap-y-24 sm:gap-y-20 lg:grid-cols-2 lg:items-center">
          <div className="lg:pr-4 order-2 lg:order-1">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
              <p className="text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl">
                Maximize Civic Contributions with Tangible Rewards
              </p>
              <p className="mt-8 text-xl/8 text-gray-300">
                USA Unity Coin changes the way you support political and social
                initiatives. Each donation through UUC symbolizes your
                commitment to the causes you care about and provides you with a
                token as a recognition of your contribution.
              </p>
              <p className="mt-8 text-xl/8 text-gray-300">
                These tokens serve as a public representation of your support
                and may have future uses as the community grows, but are not
                intended for trading or investment purposes.
              </p>
            </div>
          </div>
          <div className="-mx-4 sm:mx-0 order-1 lg:order-2">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
              <img
                alt="Product screenshot"
                src="/BlockchainBlocks.jpg"
                width={2432}
                height={1442}
                className="w-full rounded-none sm:rounded-3xl"
              />
            </div>
          </div>
        </div>

        <div className="mt-16">
          <div className="mx-auto max-w-2xl lg:max-w-none">
            <p className="text-xl/8 text-gray-300">
              UUC empowers token holders with unique benefits, including earning
              tokens through acts of kindness and having a voice in the
              community through voting rights. This approach enhances the value
              of your civic contributions, providing meaningful, real-world
              rewards while respecting the hard work of Americans who seek
              tangible impact rather than symbolic gestures.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
