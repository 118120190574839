"use client";

import React, { useState, useEffect, useMemo } from "react";
import { useWallet } from "../contexts/WalletContext";
import { Dialog, DialogPanel } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";

import logoSvg from "/uuc-logo-color.svg";

export default function Header({ userId, setUserId }) {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [navigation, setNavigation] = useState([
        {
            name: "About",
            href: "/about",
        },
    ]);
    const { address, isConnected, setConnected, disconnect } = useWallet();
    const navigate = useNavigate();

    const mobileNavItems = useMemo(() => {
        return navigation.map((item) => (
            <a
                key={item.name}
                href={item.href}
                className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-white hover:bg-gray-800"
            >
                {item.name}
            </a>
        ));
    }, [navigation]);

    useEffect(() => {
        if (isConnected && address) {
            // Fetch user data when wallet is connected
            setNavigation([
                {
                    name: "About",
                    href: "/about",
                },
                {
                    name: "Account",
                    href: "/account",
                },
            ]);
            fetch(
                `${import.meta.env.VITE_API_BASE_LOCAL_DEV}/users/walletlogin`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ walletAddress: address }),
                },
            )
                .then((res) => res.json())
                .then((data) => {
                    localStorage.setItem("token", data.token);
                    localStorage.setItem("userId", data.userId);
                    localStorage.setItem("isConnected", true);
                    setUserId(data.userId);
                    setConnected(true);
                })
                .catch((error) => {
                    console.error("Error during wallet login:", error);
                });
        } else {
            if (!isConnected) {
                setNavigation([
                    {
                        name: "About",
                        href: "/about",
                    },
                ]);

                // Handle wallet disconnect
                setUserId(null);
                localStorage.removeItem("token");
                localStorage.removeItem("userId");
                localStorage.removeItem("walletAddress");
                localStorage.removeItem("isConnected");
                setConnected(false);
            }
        }
    }, [isConnected, address, setUserId, disconnect]);

    return (
        <header className="absolute inset-x-0 top-0 z-50">
            <nav
                aria-label="Global"
                className="flex items-center justify-between p-6 lg:px-8"
            >
                <div className="flex lg:flex-1">
                    <a href="/" className="-m-1.5 p-1.5">
                        <span className="sr-only">USA Unity Coin</span>
                        <img
                            alt="USA Unity Coin Logo"
                            src={logoSvg}
                            className="h-16 w-auto"
                        />
                    </a>
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        onClick={() => setMobileMenuOpen(true)}
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon aria-hidden="true" className="size-10" />
                    </button>
                </div>

                <div className="hidden items-center gap-8 lg:flex lg:flex-1 lg:justify-end">
                    {navigation.map((item) => (
                        <a
                            key={item.name}
                            href={item.href}
                            className="text-xl/6 font-semibold text-white relative after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-0 after:bg-white after:transition-all after:duration-300 hover:after:w-full"
                        >
                            {item.name}
                        </a>
                    ))}
                    <appkit-button
                        className="login-button"
                        balance="hide"
                        label={
                            isConnected
                                ? "Wallet Connected"
                                : "Connect Wallet/Login"
                        }
                        size="md"
                    />
                    <a
                        href="/usa_unity_coin_whitepaper.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="relative rounded-md bg-amber-500 px-6 py-2.5 text-lg text-center font-semibold text-gray-900 shadow-[0_0_15px_rgba(251,191,36,0.5)] transition-all hover:bg-amber-400 hover:shadow-[0_0_25px_rgba(251,191,36,0.65)] focus:outline-none focus:ring-2 focus:ring-amber-400 focus:ring-offset-2 focus:ring-offset-gray-900 group"
                    >
                        <span className="absolute -inset-0.5 rounded-lg bg-gradient-to-r from-amber-500 via-amber-400 to-amber-500 opacity-0 transition-opacity group-hover:opacity-30 blur" />
                        <span className="relative">Read the Whitepaper</span>
                    </a>
                </div>
            </nav>
            <Dialog
                open={mobileMenuOpen}
                onClose={setMobileMenuOpen}
                className="lg:hidden"
            >
                <div className="fixed inset-0 z-50" />
                <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
                    <div className="flex items-center justify-between">
                        <a href="#" className="-m-1.5 p-1.5">
                            <span className="sr-only">Your Company</span>
                            <img alt="" src={logoSvg} className="h-14 w-auto" />
                        </a>
                        <button
                            type="button"
                            onClick={() => setMobileMenuOpen(false)}
                            className="-m-2.5 rounded-md p-2.5 text-gray-400"
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon aria-hidden="true" className="size-6" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/25">
                            <div className="space-y-2 py-6">
                                {mobileNavItems}
                            </div>
                            <div className="flex flex-col gap-4 py-6">
                                <appkit-button
                                    className="login-button"
                                    balance="hide"
                                    label={
                                        isConnected
                                            ? "Wallet Connected"
                                            : "Connect Wallet/Login"
                                    }
                                    size="md"
                                />
                                <a
                                    href="/usa_unity_coin_whitepaper.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="relative rounded-md bg-amber-500 px-6 py-2.5 text-lg text-center font-semibold text-gray-900 shadow-[0_0_15px_rgba(251,191,36,0.5)] transition-all hover:bg-amber-400 hover:shadow-[0_0_25px_rgba(251,191,36,0.65)] focus:outline-none focus:ring-2 focus:ring-amber-400 focus:ring-offset-2 focus:ring-offset-gray-900 group"
                                >
                                    <span className="absolute -inset-0.5 rounded-lg bg-gradient-to-r from-amber-500 via-amber-400 to-amber-500 opacity-0 transition-opacity group-hover:opacity-30 blur" />
                                    <span className="relative">Read the Whitepaper</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </header>
    );
}