export default function TimeForChange() {
  return (
    <div className="py-24 overflow-hidden relative">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
          <h2 className="text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl">
            Where Do Your Political and Financial Contributions Even Go?
          </h2>
        </div>

        <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-center mt-20">
          <div className="px-0">
            <div className="mx-auto max-w-md sm:max-w-lg lg:max-w-none">
              <img
                alt="Product screenshot"
                src="/unity-coin.png"
                width={2432}
                height={1442}
                className="w-full sm:rounded-3xl"
              />
            </div>
          </div>

          <div className="px-0 lg:pr-4">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
              <p className="text-pretty text-lg/8 font-medium text-gray-300 sm:text-xl/8">
                Do you ever find yourself wondering why your political donations
                seem to disappear without a trace? You may be left questioning
                how much of an impact you're truly making and where your
                hard-earned money is going…
              </p>
              <p className="mt-8 text-pretty text-lg/8 font-medium text-gray-300 sm:text-xl/8">
                We hear this time and time again. Many savvy and engaged
                citizens struggle with the transparency of political funding
                methods. The uncertainties in tracking how contributions are
                used can diminish trust and deter active participation.
              </p>
              <p className="mt-8 text-pretty text-lg/8 font-medium text-gray-300 sm:text-xl/8">
                UUC addresses these concerns by leveraging blockchain technology
                to ensure every contribution is traceable, secure, and directed
                toward its intended purpose.
              </p>
              <p className="mt-8 text-pretty text-xl font-bold text-gray-200 sm:text-2xl">
                It's time for all this to change.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
